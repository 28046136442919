<template>
  <div
    class="w-full  h-full-screen [@media_screen_and_(max-height:670px)]:h-full"
  >
    <div
      class="w-full flex justify-center items-center h-full "
    >
      <form
        class="bg-white/40 shadow-lg  backdrop-blur-lg form-body sm:max-w-sm w-full max-sm:mx-3 my-10 p-8 space-y-8"
      >
        <div class="space-y-8">
          <div>
            <div class="form-head flex justify-center" >
             <img
                src="../assets/logo/jestagroup-light-logo.png"
                alt="logo"
                class="w-full max-w-[200px] block"
              />
            </div>
          </div>

          <p class=" text-zinc-700">
            Please enter your account to continue.
          </p>
        </div>
        <div class="space-y-5">
          <div class="relative z-0 w-full mb-6 group">
            <input
              v-model="username"
              name="floating_email"
              id="floating_email"
              autocomplete="off"
              class="block py-2.5 px-0 w-full text-sm text-zinc-900 bg-transparent border-0 border-b-2 border-zinc-300 appearance-none  focus:outline-none focus:ring-0 focus:border-jestaDarkBlue peer"
              placeholder=" "
              required
            />
            <label
              for="floating_email"
              class="peer-focus:font-medium absolute text-sm text-zinc-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-jestaDarkBlue  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >Username</label
            >
          </div>
          <div class="relative z-0 w-full mb-6 group">
            <input
              type="password"
              v-model="password"
              name="floating_password"
              id="floating_password"
              class="block py-2.5 px-0 w-full text-sm text-zinc-900 bg-transparent border-0 border-b-2 border-zinc-300 appearance-none  focus:outline-none focus:ring-0 focus:border-jestaDarkBlue peer"
              placeholder=" "
              autocomplete="off"
              required
            />
            <label
              for="floating_password"
              class="peer-focus:font-medium absolute text-sm text-zinc-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-jestaDarkBlue  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >Password</label
            >
          </div>
        </div>

        <button type="button" @click="login" class="!mt-14 text-white bg-jestaDarkBlue transition-all duration-300  hover:bg-jestaDarkBlue/80  p-3 w-full ">
          Login, to continue
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { useUserSignData } from "@/store/userSignData";
import router from "@/router";

export default {
  setup() {
    const userSignData = useUserSignData();

    if (userSignData.isAuthenticated) {
      router.push("/");
    }
  },
  data() {
    return {
      username: "",
      password: "",
      isRemember: false,
    };
  },
  methods: {
    login: async function () {
      const userSignData = useUserSignData();

      userSignData.login(this.username, this.password);
    },
  },
};
</script>
