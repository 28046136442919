const fullScreenHeight = {
    // Fixed 100vh problem for mobile responsive
    mounted() {
        const documentHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
        };
        window.addEventListener("resize", documentHeight);
        documentHeight();
    },
}

export default fullScreenHeight 