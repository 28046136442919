<template>
  <!-- Portfolio -->
  <div class="present-project h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center text-sm font-medium text-zinc-700 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white"
          >
            <svg
              class="mr-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-zinc-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/portfolio/present-projects"
              class="ml-1 text-sm font-medium text-zinc-700 hover:text-zinc-900 md:ml-2 dark:text-zinc-400 dark:hover:text-white"
              >Portfolio | Past Projects</router-link
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1 class="text-2xl text-zinc-900 font-medium dark:text-zinc-200">
          Past Projects
        </h1>
        <p class="mt-1 text-sm font-normal text-zinc-400">
          View all past projects of portfolio.
        </p>
      </div>
    </nav>

    <div
      class="card flex flex-wrap gap-x-6 max-[1100px]:gap-y-3 mt-5 justify-between bg-white dark:bg-zinc-800 w-full p-3 border dark:border-zinc-700"
    >
      <div class="flex max-sm:flex-col gap-y-3 gap-x-2 max-[1100px]:w-full">
        <div class="w-max max-[1100px]:w-1/2 max-sm:w-full">
          <dropdown placement="left">
            <template v-slot:button>
              <button
                class="flex justify-between w-full px-4 py-3 border bg-zinc-100 dark:bg-zinc-900 text-sm dark:border-zinc-700"
              >
                <span class="text-zinc-500 dark:text-zinc-400">Country</span>

                <span class="ml-4 mt-1 text-zinc-500"
                  ><Icon icon="ant-design:caret-down-filled"
                /></span>
              </button>
            </template>
            <template v-slot:content>
              <div class="uppercase text-xs">
                <a
                  @click="() => countryChange(null)"
                  href="#"
                  class="block hover:bg-jestaBlue hover:text-white px-4 py-2"
                >
                  All</a
                >
                <a
                  @click="() => countryChange(item.id)"
                  v-for="item in allCountries"
                  :key="item.id"
                  href="#"
                  class="block hover:bg-jestaBlue hover:text-white px-4 py-2"
                >
                  {{ item.name }}</a
                >
              </div>
            </template>
          </dropdown>
        </div>

        <div class="w-max max-[1100px]:w-1/2 max-sm:w-full">
          <dropdown placement="left">
            <template v-slot:button>
              <button
                class="flex justify-between w-full px-4 py-3 border bg-zinc-100 dark:bg-zinc-900 text-sm dark:border-zinc-700"
              >
                <span class="text-zinc-500 dark:text-zinc-400">Type</span>

                <span class="ml-4 mt-1 text-zinc-500"
                  ><Icon icon="ant-design:caret-down-filled"
                /></span>
              </button>
            </template>
            <template v-slot:content>
              <div class="uppercase text-xs">
                <a
                  @click="() => typeChange(null)"
                  href="#"
                  class="block hover:bg-jestaBlue hover:text-white px-4 py-2"
                >
                  All</a
                >
                <a
                  v-for="item in allProjectTypes"
                  @click="() => typeChange(item.id)"
                  :key="item.id"
                  href="#"
                  class="block hover:bg-jestaBlue hover:text-white px-4 py-2"
                  >{{ item.type }}</a
                >
              </div>
            </template>
          </dropdown>
        </div>

        <div class="w-max max-[1100px]:w-1/2 max-sm:w-full">
          <dropdown placement="left" class="w-full">
            <template v-slot:button>
              <button
                class="flex justify-between w-full px-4 py-3 border bg-zinc-100 dark:bg-zinc-900 text-sm dark:border-zinc-700"
              >
                <span class="text-zinc-500 dark:text-zinc-400">{{ currentLang }}</span>

                <span class="ml-4 mt-1 text-zinc-500"
                  ><Icon icon="ant-design:caret-down-filled"
                /></span>
              </button>
            </template>
            <template v-slot:content>
              <div class="uppercase text-xs">
                <button
                  @click="changeLang('En')"
                  type="button"
                  class="block hover:bg-jestaBlue hover:text-white px-4 py-2 w-full text-left"
                >
                  En
                </button>
                <button
                  type="button"
                  @click="changeLang('Fr')"
                  class="block hover:bg-jestaBlue hover:text-white px-4 py-2 w-full text-left"
                >
                  Fr
                </button>
              </div>
            </template>
          </dropdown>
        </div>
      </div>
      <div
        class="input-box border bg-zinc-100 dark:bg-zinc-900 dark:border-zinc-700 w-fit max-[1100px]:w-full box-border flex focus-within:bg-zinc-100 dark:focus-within:bg-zinc-700"
      >
        <div class="text-3xl p-2 text-zinc-400">
          <Icon icon="ei:search" />
        </div>

        <input
          v-model="searchContent"
          type="text"
          placeholder="Search..."
          class="p-3 w-full bg-zinc-100 dark:bg-zinc-900 dark:text-zinc-400 outline-none focus:bg-zinc-100 dark:focus:bg-zinc-700"
        />
      </div>
    </div>

    <div
      v-if="!isLoading && portfolios.length > 0"
      class="grid w-full grid-cols-4 max-[1350px]:grid-cols-3 max-[1150px]:grid-cols-2 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-y-16 mt-6"
    >
      <!-- Portfolio box list -->
      <PortfolioBox
        @removeProjectClick="removeProjectClick($event)"
        v-for="(item, index) in portfolios"
        :key="index"
        :title="item.title"
        :id="item.id"
        :number="++index"
        :picture-url="item.pictureOneUrl"
        :types="item.types"
        :country="item.country"
        :city="item.city"
        :is-former-transaction="item.isFormerTransaction"
      />
    </div>

    <div
      v-if="portfolios.length === 0 && !isLoading"
      class="w-full h-full flex justify-center items-center mt-20"
    >
      <span>Project list is empty</span>
    </div>

    <div
      class="flex justify-center items-center w-full h-full p-16 pt-[140px]"
      v-if="isLoading"
    >
      <LoadingSpinner class="text-logicaTertiary dark:text-logicaSecondary" />
    </div>

    <div
      class="w-full flex justify-center mt-10 max-sm:mt-8"
      v-if="pagination.hasNext && !isLoading"
    >
      <div
        class="w-full max-w-[350px] max-sm:max-w-[250px] flex flex-col justi items-center gap-3"
      >
        <hr class="w-full h-1 max-sm:h-[2px] bg-jestaBrown mt-5 border-none" />
        <button
          @click="showMore"
          type="button"
          class="flex flex-col justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            class="fill-jestaBrown w-16 max-sm:w-10 h-16 max-sm:h-10"
            viewBox="0 0 256 256"
            xml:space="preserve"
          >
            <defs></defs>
            <g
              style="
                stroke: none;
                stroke-width: 0;
                stroke-dasharray: none;
                stroke-linecap: butt;
                stroke-linejoin: miter;
                stroke-miterlimit: 10;
                fill: none;
                fill-rule: nonzero;
                opacity: 1;
              "
              transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
            >
              <path
                d="M 90 23.25 c 0 -0.384 -0.146 -0.768 -0.439 -1.061 c -0.586 -0.586 -1.535 -0.586 -2.121 0 L 45 64.629 L 2.561 22.189 c -0.585 -0.586 -1.536 -0.586 -2.121 0 c -0.586 0.586 -0.586 1.535 0 2.121 l 43.5 43.5 c 0.585 0.586 1.536 0.586 2.121 0 l 43.5 -43.5 C 89.854 24.018 90 23.634 90 23.25 z"
                style="
                  stroke: none;
                  stroke-width: 1;
                  stroke-dasharray: none;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-miterlimit: 10;
                  fill: #af8759;
                  fill-rule: nonzero;
                  opacity: 1;
                "
                transform=" matrix(1 0 0 1 0 0) "
                stroke-linecap="round"
              />
            </g>
          </svg>

          <span
            class="uppercase text-jestaBrown font-semibold text-base max-sm:text-sm tracking-[0px]"
            >Show More</span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "../../components/Dropdown.vue";
import PortfolioBox from "../../components/Portfolio/PortfolioBox.vue";
import axios from "axios";
import settings from "@/settings/appSettings";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
    Dropdown,
    PortfolioBox,
    LoadingSpinner,
  },
  data() {
    return {
      portfolios: [],
      allCountries: [],
      allProjectTypes: [],
      searchContent: null,
      isLoading: true,
      pagination: {},
      typeId: null,
      countryId: null,
      currentLang: "En",
    };
  },
  async created() {
    await this.getAllCountries();
    await this.getAllProjectTypes();
    await this.getProjects();
  },
  methods: {
    async getAllCountries() {
      await axios
        .get("Portfolios/GetAllCountries?IsFormerTransaction=false")
        .then((response) => {
          this.allCountries = response.data?.value;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    async getAllProjectTypes() {
      await axios
        .get(`Portfolios/GetAllTypes?IsFormerTransaction=false&lang=${this.currentLang}`)
        .then((response) => {
          this.allProjectTypes = response.data?.value;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    async getProjects(
      countryId = null,
      typeId = null,
      searchContent = null,
      currentPage = null
    ) {
      if (!currentPage) {
        this.isLoading = true;
        this.portfolios = [];
      }

      let url = `Portfolios?IsFormerTransaction=true&PageSize=15&lang=${this.currentLang}`;

      if (countryId) {
        url += `&CountryId=${countryId}`;
      }

      if (typeId) {
        url += `&PortfolioTypeId=${typeId}`;
      }

      if (searchContent) {
        url += `&SearchValue=${searchContent}`;
      }

      if (currentPage) {
        url += `&PageNumber=${currentPage}`;
      }

      setTimeout(async () => {
        await axios
          .get(url)
          .then((response) => {
            response.data?.value?.forEach((element) => {
              element.pictureOneUrl = `${settings.baseUrl}/${element.pictureOneUrl}`;

              element.pictureTwoUrl = `${settings.baseUrl}/${element.pictureTwoUrl}`;
            });

            if (currentPage) {
              this.portfolios = this.portfolios.concat(response.data?.value);
            } else {
              this.portfolios = response.data?.value;
            }

            this.pagination = response.data?.pagination;

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            console.log(error.response);
          });
      }, 250);
    },
    async countryChange(countryId) {
      this.countryId = countryId;
      await this.getProjects(countryId);
    },
    async typeChange(typeId) {
      this.typeId = typeId;
      await this.getProjects(null, typeId);
    },
    async showMore() {
      await this.getProjects(
        this.countryId,
        this.typeId,
        null,
        ++this.pagination.currentPage
      );
    },
    async removeProjectClick(projectId) {
      this.isLoading = true;

      await axios
        .delete(`Portfolios/${projectId}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          await this.getProjects();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Remove Failed!",
              body: error.response?.data?.messages[0],
            });
          }
          this.isLoading = false;
        });
    },
    async changeLang(lang) {
      this.currentLang = lang;

      await this.getProjects();

      await this.getAllProjectTypes();
    },
  },
  watch: {
    searchContent(val) {
      this.searchContent = val;

      if (this.searchContent) {
        window.scrollTo(0, 0);

        this.getProjects(null, null, this.searchContent);
      } else {
        window.scrollTo(0, 0);

        this.getProjects();
      }
    },
  },
};
</script>
