<template>
  <div class="container h-full-screen flex items-center justify-center lg:max-w-7xl mx-auto text-center ">
    <div class="sm:space-y-8 space-y-5 px-4 py-12">
      <h1 class="sm:text-9xl text-8xl dark:text-white text-zinc-800 font-bold">404</h1>
      <h2 class="sm:text-4xl xs:text-2xl text-xl dark:text-white text-zinc-800">
        This Page Not Found
      </h2>
      <p class="dark:text-zinc-400 max-w-2xl mx-auto max-sm:text-sm text-zinc-800">
        Sorry, the page you are looking for was not found!
      </p>
      <button
        @click="this.$router.push('/')"
        class="bg-jestaDarkBlue dark:bg-jestaBrown rounded-lg text-white py-3 px-5 max-sm:text-sm"
      >
        Return To Home
      </button>
    </div>
  </div>
</template>