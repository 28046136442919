<template>
  <editor
    api-key="14w7rph5k02am81sws6r3hy568qta011pppiytoxlusbplv3"
    ref="textEditor"
    :init="{
      height: 300,
      menubar: false,
      directionality: 'ltr',
      plugins: [
        'advlist autolink directionality lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | ltr rtl | formatselect | bold italic forecolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat',
    }"
    v-model="content"
  />
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    editor: Editor,
  },
  props: {
    initialValue: {
      type: String,
      requirde: false,
    },
  },
  created() {
    this.content = this.initialValue;
  },
  data() {
    return {
      content: "",
    };
  },
  mounted() {},
  methods: {},
  watch: {
    content(val) {
      this.$emit("valueChanged", val);
    },
  },
};
</script>

<style lang="scss">
html {
  $border-width: 2px;

  // Light theme
  $border-light: rgb(228 228 231);
  $background-light: rgb(244 244 245);
  $text-light: rgb(82 82 91);
  // @apply border-zinc-200;
  // @apply bg-zinc-100;
  // @apply text-zinc-600;

  // Dark theme
  $border-dark: rgb(63 63 70);
  $background-dark: rgb(9 9 11);
  $text-dark: rgb(113 113 122);
  // @apply border-zinc-700;
  // @apply bg-zinc-950;
  // @apply text-zinc-500;

  //   Light Theme
  // Text editor base
  .tox {
    border: $border-width solid;
    border-color: $border-light;
    border-radius: 6px;

    // Footer - statusbar
    .tox-statusbar {
      background-color: $background-light;
      border-top: $border-width solid;
      border-color: $border-light;
    }
    // Footer - statusbar - Type of tag
    .tox-statusbar__path-item {
      color: $text-light;
    }
    // Footer - statusbar - Wordcount
    .tox-statusbar__wordcount {
      color: $text-light;
    }
    // Footer - statusbar - Tinymce Title
    .tox-statusbar__branding {
      display: none;
    }
    // Footer - statusbar - Resize Handle
    .tox-statusbar__resize-handle svg {
      fill: $text-light;
    }

    // Header - toolbar
    .tox-toolbar,
    .tox-toolbar__primary {
      background: none;
      background-color: $background-light;
      border-bottom: $border-width solid;
      border-color: $border-light;
    }
    // Header - toolbar - Buttons Border
    .tox-toolbar__group:not(:last-of-type) {
      border-right: $border-width solid !important;
      border-color: $border-light !important;
    }
    // Header - toolbar - Buttons
    .tox-tbtn {
      color: $text-light;

      svg {
        fill: $text-light;
      }
    }

    // Main - editor
    .tox-edit-area__iframe {
      background-color: $background-light;
    }
  }

  //   Dark Theme
  &.dark {
    // Text editor base
    .tox {
      border-color: $border-dark;

      // Footer - statusbar
      .tox-statusbar {
        align-items: center;
        background-color: $background-dark;
        border-color: $border-dark;
      }
      // Footer - statusbar - Type of tag
      .tox-statusbar__path-item {
        color: $text-dark;
      }
      // Footer - statusbar - Tinymce Title
      .tox-statusbar__wordcount {
        color: $text-dark;
      }
      // Footer - statusbar - Tinymce Title
      .tox-statusbar__resize-handle svg {
        fill: $text-dark;
      }

      // Header - toolbar
      .tox-toolbar,
      .tox-toolbar__primary {
        background-color: $background-dark;
        border-color: $border-dark;
      }
      // Header - toolbar - Buttons Border
      .tox-toolbar__group:not(:last-of-type) {
        border-color: $border-dark !important;
      }
      // Header - toolbar - Buttons
      .tox-tbtn {
        color: $text-dark;

        svg {
          fill: $text-dark;
        }
      }
    }
  }
}
</style>
